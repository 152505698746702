import React from "react";

const NoPage: React.FC<any> = (props: any) => {

  return (
    <div className="pt-16 fan min-h-screen">PAGE MISSING
    </div>
  )
}

export default NoPage;